import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import dragdataPlugin from "../../utils/chartjs-plugin-dragdata";
import { Chart, registerables } from "chart.js";
import Loading from "../Loading";
import { useSelector, useDispatch } from "react-redux";
import {
  addRefineData,
  clearRefineData,
} from "../../redux/reducers/refineDataSlice";
import { refinePrices, graphPrices } from "../../api/data";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip";
Chart.register(...registerables, dragdataPlugin);

const RefineChart = ({
  checkedPrice,
  chartDatas,
  fuelFamily,
  fuelOrigin,
  fuelTypeName,
  selectedBasin,
  loading,
  allFuelData,
}) => {
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );
  const dispatch = useDispatch();

  const refineArrayData = useSelector(
    (state) => state.refineDataSlice.refineData
  );

  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("Price by MMBtu (LHV)");
  const [isEnabled, setIsEnabled] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);
  const canAccessRoute = (requiredPermission) => {
    return permissions.some(
      (permission) =>
        permission.licensePermission.permissionName === requiredPermission
    );
  };

  useEffect(() => {
    if (checkedPrice === true) {
      setTitle("Price by MMBtu (LHV)");
    } else {
      setTitle("Price by Mt");
    }
  }, [checkedPrice]);

  useEffect(() => {
    if (!chartDatas || Object.keys(chartDatas).length === 0) {
    setLabel([]);
    setData([]);
    return;
    }
  
    let getSelectedFuelArray = [];
    allFuelData.forEach((fuel) => {
      fuelOrigin.forEach((origin) => {
        if (fuel?.family?.fuelFamilyName === fuelFamily?.item && fuel?.origin?.id === origin.id) {
          getSelectedFuelArray.push(fuel);
        }
      });
    });
  
    // Extract all available years
    const getUniqueYears = (fuels) => {
      const yearsSet = new Set();
  
      for (const fuelType in fuels) {
        const fuelData = fuels[fuelType];
        for (const entry of fuelData) {
          yearsSet.add(entry.year);
        }
      }
      return [...yearsSet].sort((a, b) => a - b);
    };
  
    const fuelValues = Object.values(chartDatas)[0];
    const uniqueYears = getUniqueYears(fuelValues);
    setLabel(uniqueYears);
  
    const fuelDatasetsNormalized = getSelectedFuelArray.map((fuel) => {
      const fuelTypeId = fuel.id;
      const fuelData = fuelValues[fuelTypeId] || [];
  
      // Convert array to a map for quick lookup
      const fuelDataMap = new Map(fuelData.map((entry) => [entry.year, entry]));
  
      // Fill missing years with `null`
      const normalizedData = uniqueYears.map((year) => {
        return fuelDataMap.has(year)
          ? checkedPrice
            ? +fuelDataMap.get(year).price_LHV.toFixed(1)
            : +fuelDataMap.get(year).price_mt.toFixed(1)
          : null;
      });
  
      return {
        label: fuel.fuelTypeName,
        data: normalizedData,
        data_mt: uniqueYears.map((year) => fuelDataMap.get(year)?.price_mt?.toFixed(1) || null),
        data_lhv: uniqueYears.map((year) => fuelDataMap.get(year)?.price_LHV?.toFixed(1) || null),
        fuelId: fuelTypeId,
        borderDash:
          fuel?.origin?.pattern === "dash"
            ? [6, 6]
            : fuel?.origin?.pattern === "dot"
            ? [2, 2]
            : [0, 0],
        backgroundColor: fuel?.family?.colorHex,
        borderColor: fuel?.family?.colorHex,
        fill: false,
        lineTension: 0.4,
        radius: 1,
        borderWidth: 2,
        pointHitRadius: 25,
        pointRadius: 3,
      };
    });
  
    setData(fuelDatasetsNormalized);
  }, [chartDatas, fuelOrigin, fuelFamily, fuelTypeName, allFuelData]);

  const chartData = {
    labels: label,
    datasets: data,
  };

  const options = {
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest', // Ensure hover is triggering on nearest point
      intersect: false, // Tooltips can show even when hovering between points
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value) {
            return "$" + value;
          },
        },
        title: {
          display: true,
          text: `${
            checkedPrice ? "Fuel Price [USD/MMBtu]" : "Fuel Price [USD/Mt]"
          }`,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;
      
            // Only show tooltip when we have data available
            if (dataset.data[dataIndex] !== null && dataset.data[dataIndex] !== undefined) {
              const price_mt = dataset.data_mt[dataIndex];
              const price_lhv = dataset.data_lhv[dataIndex];
              return [
                `${dataset.label}`,
                `Mt Price: $${isNaN(parseFloat(price_mt)) ? 'N/A' : parseFloat(price_mt).toFixed(2)}/mt`,
                `LHV Price: $${isNaN(parseFloat(price_lhv)) ? 'N/A' : parseFloat(price_lhv).toFixed(2)}/MMBtu`
              ];
            }
            return "No Data Available";
          },
        },
      },
      legend: {
        position: "bottom",
        title: {
          text: `${checkedPrice ? "Price by MMBtu (LHV)" : "Price by Mt"}`,
        },
        labels: {
          padding: 20,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
          boxWidth: 70,
          boxHeight: 0,
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 4;
              label.lineDashOffset = 5;
            });
            return originalLabels;
          },
        },
      },
      datalabels: {
        display: false,
      }
    },
  };

  if (canAccessRoute("EditPriceForecasts")) {
    options.plugins.dragData = {
      round: 1,
      showTooltip: true,
      onDragStart: (e) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          e.preventDefault();
          return;
        }
      },
      onDragEnd: async (event, datasetIndex, dataIndex, value) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          event.preventDefault();
          return;
        }

        const draggedDataset = data[datasetIndex];
        const priceYr = label[dataIndex];
        let unit = checkedPrice ? "mmbtu" : "mt"; 

        let addDragData = {
          myBasin: selectedBasin.id,
          scenario: priceScenario.id,
          ft: draggedDataset.fuelId,
          priceYear: priceYr,
          price: value,
        };

        draggedDataset.data[dataIndex] = value;

        if (checkedPrice) {
          draggedDataset.data_lhv[dataIndex] = value;
        } else {
          draggedDataset.data_mt[dataIndex] = value;
        }

        dispatch(addRefineData(addDragData));

        try {
          const response = await graphPrices(unit, addDragData);
          const updatedPrices = response.data;
          
          draggedDataset.data_mt[dataIndex] = updatedPrices.price_mt;
          draggedDataset.data_lhv[dataIndex] = updatedPrices.price_LHV;

          setData(prevData => {
            const newData = [...prevData];
            newData[datasetIndex].data[dataIndex] = draggedDataset.data[dataIndex] ;
            newData[datasetIndex].data_mt[dataIndex] = draggedDataset.data_mt[dataIndex];
            newData[datasetIndex].data_lhv[dataIndex] = draggedDataset.data_lhv[dataIndex];
            return newData;
          });
        } catch (err) {
          console.log("Failed to fetch updated prices", err);
        }
      }

    }
  }

  const addRefinePrice = (unit, refinePriceData) => {
    refinePrices(unit, refinePriceData)
      .then(() => {
        toast.success("Your changes have been saved", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "updated_refine_data",
        });
      })
      .catch((err) => console.log("refine price err", err));
  };

  const handleRefineData = () => {
    const priceUnit = checkedPrice ? "mmbtu" : "mt";
      addRefinePrice(priceUnit, refineArrayData);
  
      dispatch(clearRefineData());
  }

  const enableConfirment = () => {
    return (
      priceScenario.item === "Select" ||
      fuelFamily.item === "Select" ||
      selectedBasin.item === "Select" ||
      !priceScenario.item ||
      !fuelFamily.item ||
      !selectedBasin.item ||
      refineArrayData.length === 0
    );
  };

  useEffect(() => {
    const isDisabled = enableConfirment();
    setIsEnabled(isDisabled);
  }, [priceScenario, fuelFamily, selectedBasin]);


  return (
    <>
      <div className="refine-price-chart-container">
        <p className="mb-0 d-none">{title}</p>
        <div className="chart-container border-1 rounded-3 mt-2">
          {label.length === 0 ||
            data.length === 0 ||
            (loading && (
              <div className="d-flex align-items-center justify-content-center h-100">
                <Loading
                  loading={label.length === 0 || data.length === 0 || loading}
                  height={30}
                />
              </div>
            ))}
          {label.length > 0 && data.length > 0 && !loading && (
            <Line options={options} data={chartData} />
          )}
        </div>
      </div>
      <div className="actions mt-3 d-flex justify-content-end w-100 gap-3">
        {(canAccessRoute("EditPriceForecasts") || canAccessRoute("BatchPriceEditor")) && (
          <div>
            {isEnabled && refineArrayData.length === 0 ? (
              <CustomTooltip
                tooltipText={"This control is enabled only after changes are made in the graph. Drag and drop each price point and then confirm your changes."}
                placement="top"
              >
                <button className="btn btn-primary btn-lg fs-16" disabled={enableConfirment()} onClick={() => handleRefineData()}>
                  Confirm Refinement
                </button>
              </CustomTooltip>
            ) : (
              <button className="btn btn-primary btn-lg fs-16" disabled={enableConfirment()} onClick={() => handleRefineData()}>
                Confirm Refinement
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RefineChart;
